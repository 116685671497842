@font-face {
  font-family: "Trade Gothic LT Pro";
  font-weight: bold;
  src: url("../font/TradeGothicLTPro-Bold.ttf");
}

@font-face {
  font-family: "Trade Gothic LT Pro";
  /* font-weight: normal; */
  src: url("../font/TradeGothicLTPro-BdCn20.ttf");
}

@font-face {
  font-family: "ROG";
  src: url("../font/ROGFonts-Regular.otf");
}

